import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import { SelectedCheckbox, UnSelectedCheckbox, Search } from '@/assets';
import TextWithTooltip from '@components/TextWithTooltip';

import { checkIclusion } from '@modules/AddContactModal/components/Tags/helpers';

import { Autocomplete, StyledPaper, TagWrapper, Container, TextField } from './styles';

// TODO ts migrate
function TagSearch({
  options,
  onChange,
  placeholder,
  noOptionsText,
  selectedTags
}) {
  const changeHandler = (e, a, o, { option }) => {
    onChange(option);
  };

  return (
    <Container>
      <Autocomplete
        ListboxProps={ {
          style: {
            paddingTop: 4
          }
        } }
        PaperComponent={ StyledPaper }
        disableClearable
        disableCloseOnSelect
        getOptionLabel={ (option) => option.label || 'label' }
        getOptionSelected={ () => false }
        multiple
        noOptionsText={ noOptionsText }
        onChange={ changeHandler }
        options={ options }
        renderInput={ (params) => (
          <TextField
            { ...params }
            endAdornment={ <Search /> }
            placeholder={ placeholder }
            variant='outlined'
          />
        ) }
        renderOption={ (option) => {
          const checked = checkIclusion(option, selectedTags);
          return (
            <TagWrapper checked={ checked }>
              <Checkbox
                checked={ checked }
                checkedIcon={ <SelectedCheckbox /> }
                icon={ <UnSelectedCheckbox /> }
                style={ { marginRight: 8, padding: 0 } }
              />
              <TextWithTooltip text={ option.label || '' } />
            </TagWrapper>
          );
        } }
        size='small'
      />
    </Container>
  );
}

export default TagSearch;
