import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@store';

import {
  CATEGORIES,
  DUE_DATE_TYPES,
  EDIT_MODE_STAGES_OPTIONS,
  TO_DOS_LINKED_ITEMS_TYPES_SCHEME,
  STAGES,
  TO_DOS_FIELDS_NAMES
} from '@constants/todosData';
import { COLORS } from '@constants/colors';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { getPriorityColor } from '@/shared/utils/getPriorityColor';
import { MODAL_TYPE } from '@constants/modalTypes';
import { useDebounceValue, useDidUpdate } from '@customHooks';

import { getAvatarData } from '@/shared/utils/getAvatarData';
import { validationSchema } from './validationScheme';

import { DueDate } from './components';
import { OutOfSightTD } from '@components/OutOfSight';
import Avatar from '@components/Avatar';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import Label from '@components/Label';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import LinkedContactCell from '@modules/LinkedContactCell';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import PopupSingleActionButton, { POPUP_BUTTON_TYPES } from '@components/PopupSingleActionButton';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import Tooltip from '@components/Tooltip';

import { Check, ColorStarInCircle, Timer } from '@/assets';

import { CompletedIconWrapper, CounterWrapper, FormCellWrapper, IconsWrapper } from './styles';

import { DotsMenuItemTypes } from '@components/DotsMenu/types';
import { TodoGridFormField } from '@/shared/types/todos';
import { TodosRowProps } from './types';
import { TypeSelectedRow } from '@components/Table/components/Row/types';
import { ValueLabelObj } from '@/shared/types/commonTypes';
import { getUTCFormattedString } from '@/shared/utils/getUTCFormattedString';
import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';

const DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE = 'todo-name';
const SINGLE_ACTION_BUTTON_STYLE_PROPS = {
  style: {
    minWidth: '28px',
    paddingRight: '0px'
  }
};

export const TodosRow = observer(({
  data,
  isCheckboxActive,
  onCheckboxClickCb,
  onDeleteTodo,
  onEditTodo,
  onRowBookmarkCb,
  priorityOptions,
  setActiveRowData,
  setIsSideBarPanelOpen,
  updateFromGrid,
}: TodosRowProps) => {

  const modalStore   = useStore().ModalStore;
  const { id: currentUserId } = useStore().SettingsStore.profile;

  const alternativeClickHandler = useCallback(() => {
    if(setIsSideBarPanelOpen && setActiveRowData) {
      setIsSideBarPanelOpen();
      setActiveRowData(data);  
    }
  }, [setIsSideBarPanelOpen]);

  const defaultValues = useMemo(() => {
    return {
      stage: data.stage,
      priority: data.priority,
      dueDate: data.dueDate,
      id: data.id
    };
  }, [ data ]);

  const { control, formState, setValue } = useForm<TodoGridFormField>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues,
  });

  const isBlocked = (typeof data?.linkedItemData?.editAllow === 'number' && !data?.linkedItemData?.editAllow) ||
    (typeof data?.editAllow === 'number' && !data?.editAllow);

  const allFields = useWatch({
    control,
    name: [
      TO_DOS_FIELDS_NAMES.id,
      TO_DOS_FIELDS_NAMES.dueDate,
      TO_DOS_FIELDS_NAMES.priority,
      TO_DOS_FIELDS_NAMES.stage,
    ]
  });
  const [ id, dueDate, priority, stage ] = allFields;

  const allFieldsToObject = useMemo(() => ({
    id,
    dueDate,
    priority,
    stage
  }), [ dueDate, id, priority, stage ]);

  const isCompleted = data.stage === STAGES.complete;
  const isSkipped = data.stage === STAGES.skipped;
  const isAssigned = data.clientData.id !== currentUserId;

  const formattedLastUpdated = data.createdAt && data.updatedCount && data.updatedCount > 0 ?
    getUTCFormattedString(data.createdAt, MONTH_DAY_YEAR_BACKSLASH) : '-';

  const debounce = useDebounceValue(allFields, 100);

  const mainImageData = useMemo(() => getAvatarData(data.clientData).mainImageData, [ data.clientData ]);

  const menuOptions = useMemo(() => {
    const arrayOfTypes = [
      DOTS_MENU_ITEM_TYPES.edit,
      DOTS_MENU_ITEM_TYPES.completeAndCopy,
      DOTS_MENU_ITEM_TYPES.copyTask,
      DOTS_MENU_ITEM_TYPES.delete
    ].filter((type) => (isCompleted || isAssigned) ? type !== DOTS_MENU_ITEM_TYPES.completeAndCopy : type);

    return getDotsMenuOptions({
      arrayOfTypes,
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.delete ]
    });
  }, []);

  const onBookmarkClick = useCallback((state: boolean) => {
    onRowBookmarkCb(data.id, state);
  }, [ onRowBookmarkCb, data ]);

  const onCheckboxClick = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onCheckboxClickCb(data)(event,checked);
  }, [ onCheckboxClickCb, data ]);

  const onEditWrapper = useCallback(() => {
    onEditTodo(data.id);
  }, [data.id, onEditTodo]);

  const onQuickCompleteClick = useCallback(() => {
    setValue(TO_DOS_FIELDS_NAMES.stage, STAGES.complete);
  }, [setValue]);

  const onMenuSelect = useCallback(async(action: DotsMenuItemTypes) => {
    if(action === DOTS_MENU_ITEM_TYPES.edit) {
      onEditWrapper();
    }
    if(action === DOTS_MENU_ITEM_TYPES.delete) {
      onDeleteTodo(data.id, data.requirring);
    }
    if(action === DOTS_MENU_ITEM_TYPES.completeAndCopy) {
      const isCloneTask = true;
      onQuickCompleteClick();
      onEditTodo(data.id, isCloneTask, { stage: data.stage });
    }
    if(action === DOTS_MENU_ITEM_TYPES.copyTask) {
      const isCloneTask = true;
      onEditTodo(data.id, isCloneTask);
    }
  }, [data.id, data.requirring, onDeleteTodo, onEditWrapper]);

  useDidUpdate(() => {
    const isDateValid = !formState?.errors?.[TO_DOS_FIELDS_NAMES.dueDate];
    const newCount = data.updatedCount ? data.updatedCount + 1 : 1;
    const updatedAt = format(new Date(), MONTH_DAY_YEAR_BACKSLASH);
    const newPriority = stage === STAGES.forNextMeeting ? null : priority;
    
    if (isDateValid) {
      updateFromGrid({
        ...allFieldsToObject,
        updatedCount: newCount,
        createdAt: updatedAt,
        priority: newPriority
      });
    }
  }, [ debounce ]);

  const isAbleToOpenSideBarPanel = useMemo(() => {
    return data.linkedItemData &&
      data.linkedItemData.linkedContactType === TO_DOS_LINKED_ITEMS_TYPES_SCHEME.contactData.type &&
      data.stage !== STAGES.complete;
  }, [ data.linkedItemType, data.stage ]);

  const typeSelectedRow = useMemo(() => {
    if(isSkipped || isCompleted) {
      return TypeSelectedRow.Stripes;
    }
    return;
  }, [ isSkipped, isCompleted ]);

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  return (
    <>
      <Table.Row
        $preventPointerEvents={ isBlocked }
        $typeSelectedRow={ typeSelectedRow }
        hover
        onDoubleClick={ onEditWrapper }
        role='checkbox'
        selected={ isSkipped || isCompleted }
        tabIndex={ -1 }
      >
        <Table.CellWithControls
          bookmarkIconProps={ {
            isActive: data.flagged,
            onStateChange: onBookmarkClick,
          } }
          checkboxProps={ {
            checked: isCheckboxActive,
            onChange: onCheckboxClick
          } }
        />
        <Table.Cell
          dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
          onClick={ onEditWrapper }
        >
          <TextWithTooltip
            dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
            text={ data.name }
          />
          <IconsWrapper>
            { 
              data.dueDateType === DUE_DATE_TYPES.mustDoToday && <Timer/>
            }
            {
              Boolean(Number(data.isNew)) &&
              <Label
                $bgColor={ COLORS.pink } 
                $color={ COLORS.white }
              >
                NEW
              </Label>
            }
            {
              data.request &&
              <Tooltip title={ CATEGORIES.officialRequests }>
                <ColorStarInCircle/>
              </Tooltip>
            }
          </IconsWrapper>
        </Table.Cell>
        <LinkedContactCell
          $isClickable={ isAbleToOpenSideBarPanel }
          UserItemProps={ {
            isLinkedContactType: data.linkedItemData && Boolean(data.linkedItemData.linkedContactType)
          } }
          alternativeClickHandler={ alternativeClickHandler }
          contact={ data.linkedItemData }
          isSideBarPanel={ isAbleToOpenSideBarPanel }
          tab={ CONTACT_DETAILS_TAB.profile }
        >
        </LinkedContactCell>
        <Table.Cell>
          <FormCellWrapper $maxWidth={ '120px' }>
            { data.priority || (!data.priority && data.stage !== STAGES.forNextMeeting) ? 
              <HFSelect
                control={ control }
                disabled={ isCompleted || isSkipped }
                name={ TO_DOS_FIELDS_NAMES.priority }
                options={ priorityOptions }
                renderItem={ (option) => {
                  if(!option) {
                    return '';
                  }
                  const { value, label } = option as ValueLabelObj;
                  return (
                    <ListItemWithColorIndicator
                      indicatorColor={ getPriorityColor(value) }
                      label={ label }
                    />
                  );
                } }
                showError={ false }
              /> : '-' }
            
          </FormCellWrapper>
        </Table.Cell>
        <Table.Cell>
          <FormCellWrapper $maxWidth={ '135px' }>
            <HFSelect
              control={ control }
              disabled={ isCompleted || isAssigned || isSkipped }
              name={ TO_DOS_FIELDS_NAMES.stage }
              options={ EDIT_MODE_STAGES_OPTIONS }
              showError={ false }
            />
          </FormCellWrapper>
        </Table.Cell>
        <Table.Cell>
          <FormCellWrapper $maxWidth={ '120px' }>
            <DueDate
              control={ control }
              dateValue={ dueDate }
              isDisabled={ isCompleted || isSkipped || data.dueDateType === DUE_DATE_TYPES.mustDoToday }
              name={ TO_DOS_FIELDS_NAMES.dueDate }
            />
          </FormCellWrapper>
        </Table.Cell>
        <Table.Cell>
          { formattedLastUpdated }
          <CounterWrapper>{ data.updatedCount || 0 }</CounterWrapper>
        </Table.Cell>
        <Table.Cell $paddingLeft={ '5px' }>
          <Avatar
            mainImageData={ mainImageData }
          />
        </Table.Cell>
        <Table.Cell>
          {
            data.stage === STAGES.complete
              ? (
                <Tooltip title={ 'Completed' }>
                  <CompletedIconWrapper>
                    <Check />
                  </CompletedIconWrapper>
                </Tooltip>
              )
              : (
                <PopupSingleActionButton
                  disabled={ Object.values(formState.errors)?.length > 0 }
                  onClick={ onQuickCompleteClick }
                  type={ POPUP_BUTTON_TYPES.done }
                  { ...SINGLE_ACTION_BUTTON_STYLE_PROPS }
                >
                </PopupSingleActionButton>
              )
          }
          <DotsMenu
            $marginLeft={ 'auto' }
            $marginRight={ '10px' }
            items={ menuOptions }
            onSelect={ onMenuSelect }
          />
        </Table.Cell>
        {
          isBlocked &&
          <OutOfSightTD onClick={ onOutOfSightClick } />
        }
      </Table.Row>
    </>
  );
});
