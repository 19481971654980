import { LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { NOTES_LINKED_ITEMS_IDS } from '@constants/overview';

import { ContactItem } from '@/shared/types/contact';
import { ContactNote, ContactNoteData } from '@/shared/types/note';
import { IdType, ValueOf } from '@/shared/types/commonTypes';
import { SchemeType } from '@/shared/types/contactOverview';

export const notesNormalized = (data: ContactNoteData, contact: any): Array<ContactNote> => ([
  ...noteConverter(data.contactNotes, contact),
  ...noteConverter(data.accountPlansNotes, contact, LINKED_ITEM_TYPES.account),
  ...noteConverter(data.policyNotes, contact, LINKED_ITEM_TYPES.policy),
  ...noteConverter(data.taskNotes, contact, LINKED_ITEM_TYPES.task),
  ...noteConverter(data.plansNotes, contact, LINKED_ITEM_TYPES.plan),
  ...noteConverter(data.opportunityNotes, contact, LINKED_ITEM_TYPES.opportunity),
  ...noteConverter(data.salesPipeLineNotes, contact, LINKED_ITEM_TYPES.salesCycle),
]);

const noteConverter = (notes: Array<ContactNote>, contact: ContactItem, type?: SchemeType): Array<ContactNote> => {
  return notes.map((note) => {
    if(type) {
      for (const [key, value] of Object.entries(note)) {
        if(Object.values(NOTES_LINKED_ITEMS_IDS).includes(key as ValueOf<typeof NOTES_LINKED_ITEMS_IDS>) && value) {
          note.linkedItemId = value as IdType;
          note.linkedItem = {
            //@ts-ignore
            linkedContactType: type,
            contactData: contact,
          };
          if(note.linkedItem && note.linkedItem.linkedContactType === LINKED_ITEM_TYPES.opportunity) {
            note.linkedItem.opportunityStatus = note.opportunityStatus;
          }

          delete note[key as ValueOf<typeof NOTES_LINKED_ITEMS_IDS>];
        }
      }
      note.type = type;
    }
    return note;
  });
};
